@import "https://unpkg.com/open-props/open-props.min.css";

.tiles__line{
    perspective: 100vmin;
}

.effect_img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    /* scale: 1.2; */
    translate: 0 0;
}

.tiles__line-img:not(:hover) img {
    transition: translate 0.2s;
}

.tiles__line-img:hover img {
    translate:
        calc((var(--ratio-x) - 0.5) * 20%) calc((var(--ratio-y) - 0.5) * 20%);
}

.tiles__line-img {
    overflow: hidden;
    aspect-ratio: 3 / 4;
    /* width: 50vmin; */
    /* min-width: 280px; */
    /* border-radius: 8px; */
    transform-style: preserve-3d;
    touch-action: none;
}

.tiles__line-img:hover {
    transform:
        rotateX(calc((var(--ratio-y) - 0.5) * 50deg)) rotateY(calc((var(--ratio-x) - 0.5) * -40deg)) !important;
}

.tiles__line-img:not(:hover) {
    /* transform: rotateX(0deg) rotateY(0deg);
    transition: 0.2s; */
}

.tiles__line-img:after {
    content: "";
    position: absolute;
    inset: 0;
    background:
        radial-gradient(circle at calc(var(--ratio-x) * 100%) calc(var(--ratio-y) * 100%), hsl(0 0% 100% / 0.5), transparent 50%);
    background-repeat: no-repeat;
    background-blend-mode: soft-light;
    z-index: 2;
}

.tiles__line-img:before {
    content: "";
    background:
        url(https://assets.codepen.io/605876/code-icon-bg.svg) 0 0 / 18% 18%,
        linear-gradient(115deg,
            transparent 20%,
            var(--green-8) 30%,
            transparent 48% 52%,
            var(--indigo-8) 70%,
            transparent) 80% 80% / 300% 300%;
    filter: brightness(2) contrast(1);
    background-blend-mode: overlay;
    mix-blend-mode: color-dodge;
    position: absolute;
    inset: 0;
    z-index: 2;
    opacity: 0.2;
    transition: opacity 0.2s;
}

.tiles__line-img:hover:before {
    background-position:
        calc(var(--ratio-x) * 10%) calc(var(--ratio-y) * 10%),
        calc(80% + (var(--ratio-x) * -50%)) calc(80% + (var(--ratio-y) * -50%));
    opacity: 1;
}