main {
	/* display: grid;
	width: 100%;
	height: 100vh; */
	overflow: hidden;
}

body {
	--color-text: #fff;
	--color-text-menu: #000;
	--color-text-frame: #817f7f;
	--color-bg: #0c0c0c;
	--color-bg-menu: #ee8233;
	--color-link: #fff;
	--color-link-hover: #fff;
	--color-link-menu: rgb(255, 255, 255);
	--color-link-social: #ca5d2b;
	--border-radius: 2rem;
	--menu-padding: 1rem;
	--color-text-title: #ee8233;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: acumin-pro, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.isOpen{
	transition: 0.6s;
	opacity: 0 !important;
}
.isClosed{
	transition: 0.6s;
	opacity: 1 !important;
}

/* .frame {
	padding: 0.5rem 5vw;
	position: fixed;
	text-transform: uppercase;
	font-size: 0.75rem;
	color: var(--color-text-frame);
	bottom: 0;
	z-index: 50;
	width: 100%;
}

.frame__title {
	font-size: inherit;
	margin: 0 0 1rem;
	font-weight: normal;
}

.frame__title em {
	font-weight: 400;
	color: var(--color-bg-menu);
}

.frame__links {
	display: inline-block;
}

.frame__links a:not(:last-child) {
	margin-right: 1rem;
}

.content {
	grid-area: 1 / 1 / 2 / 2;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	display: grid;
	grid-template-rows: 10vh 15vh auto 1fr auto 1fr;
	grid-template-columns: 18% 1fr 1fr 18%;
	grid-column-gap: 7vw;
	grid-template-areas: 
	'... img-main img-main ...'
	'... img-main img-main ...'
	'img-left img-main img-main title-right'
	'img-left img-main img-main img-right'
	'title-left img-main img-main img-right'
	'title-left img-main img-main img-right';
}

.content__img {
	position: relative;
	background-size: cover;
	background-position: 50% 20%;
	will-change: transform;
}

.content__img--left {
	grid-area: img-left;
}

.content__img--main {
	grid-area: img-main;
}

.content__title {
	z-index: 10;
	position: relative;
	font-family: kudryashev-d-excontrast-sans, sans-serif;
	font-weight: 300;
	color: var(--color-text-title);
	margin: 0;
	line-height: 1;
	will-change: transform;
}

.content__title--left {
	grid-area: title-left;
	font-size: 4vw;
	justify-self: end;
	margin-top: 2rem;
}

.content__title--main {
	grid-area: img-main;
	display: grid;
	padding: 20vh 0 10vh;
	grid-template-rows: repeat(4,17vh);
	grid-template-areas:
		'letter-1 ...'
		'... letter-3'
		'letter-2 ...'
		'... letter-4';
	font-size: 20vh;
	align-content: center;
	justify-items: center;
	line-height: 1;
}

.content__title--main span {
	display: block;
}

.content__title--main span:first-child {
	grid-area: letter-1;
}

.content__title--main span:nth-child(2) {
	grid-area: letter-2;
}

.content__title--main span:nth-child(3) {
	grid-area: letter-3;
}

.content__title--main span:nth-child(4) {
	grid-area: letter-4;
}

.content__img--right {
	grid-area: img-right;
}

.content__title--right {
	grid-area: title-right;
	font-size: 6.5vw;
}

.content__description {
	z-index: 10;
	text-transform: uppercase;
	grid-area: 2 / 3 / 3 / 5;
	max-width: 400px;
	align-self: center;
	justify-self: center;
	line-height: 1;
	font-style: italic;
}*/

.cover-wrap {
	display: grid;
	pointer-events: none;
	grid-area: 1 / 1 / 2 / 2;
	z-index: 100;
}

.cover {
	display: grid;
	grid-area: 1 / 1 / 2 / 2;
	transform: translateY(-100%);
	overflow: hidden;
}

.cover__inner {
	background-size: cover;
	transform: translateY(100%);
	background-position: 50% 50%;
}

.cover-wrap,
.cover,
.cover__inner {
	will-change: transform;
}

.menu {
	position: absolute;
	top: 1rem;
	left: 1rem;
	right: 1rem;
	bottom: 1rem;
	z-index: 100;
	color: var(--color-text-menu);
	display: grid;
	grid-template-rows: 3rem 1fr;
	grid-template-areas: 'top''content';
	pointer-events: none;
	opacity: 1;
}

.menu--open {
	pointer-events: auto;
}

.menu__top {
	pointer-events: auto;
	z-index: 100;
	padding: 0 var(--menu-padding);
	grid-area: top;
	display: grid;
	align-items: center;
	grid-template-columns: auto 1fr auto;
	grid-template-areas: 'title nav search';
	justify-content: space-between;
	/* background: var(--color-bg-menu); */
	border-radius: var(--border-radius);
}

.menu_is_open {
	background: var(--color-bg-menu);
	transition-duration: 0.6s;
}

.menu_is_closed {
	background: none;
	transition-duration: 0.6s;
}

.menu__content-wrap {
	position: relative;
	grid-area: content;
	overflow: hidden;
	margin-top: -1.35rem;
}

.menu__content {
	overflow: hidden;
	transform: translateY(-100%);
	padding: 0 var(--menu-padding);
	height: 100%;
	display: grid;
	justify-content: space-between;
	background: var(--color-bg-menu);
	border-radius: 0 0 var(--border-radius) var(--border-radius);
	grid-template-rows: 1fr auto;
	grid-template-columns: auto 1fr auto;
	grid-template-areas:
		'links links back'
		'tagline tagline tagline';
	padding-bottom: 1rem;
	will-change: transform;
	opacity: 0;
}

.menu__back {
	position: relative;
	grid-area: back;
	height: 90px;
	align-self: start;
	margin-top: calc(5.5vh + 2rem);
}

.menu__back svg {
	display: block;
	fill: #000;
	height: 100%;
	max-height: 201px;
	transition: transform 0.3s;
}

.menu__back:hover svg,
.menu__back:focus svg {
	transform: translateY(-10px);
}

.menu__title {
	grid-area: title;
	font-family: kudryashev-d-excontrast-sans, sans-serif;
	font-weight: 300;
	margin: 0;
	align-self: center;
	justify-self: start;
	text-transform: uppercase;
	font-size: 1.65rem;
	position: relative;
	line-height: 0.5;
	margin-top: 0.15rem;
}

.menu__tagline {
	grid-area: tagline;
	font-size: 6vw;
	font-family: kudryashev-d-excontrast-sans, sans-serif;
	font-weight: 300;
	margin: 0;
	align-self: end;
	line-height: 0.8;
	position: relative;
	will-change: transform;
}

.menu__nav-top {
	grid-area: nav;
	overflow: hidden;
	/* height: 1.75rem; */
	justify-self: center;
}

.menu__nav-top a {
	margin: 0 1rem;
}

.menu__nav-content {
	grid-area: links;
	display: flex;
	flex-wrap: wrap;
	align-self: start;
	margin-top: 5.5vh;
}

.column {
	display: flex;
	flex-direction: column;
	margin: 0 1rem;
	min-width: 15ch;
	align-items: flex-start;
}

.column__title {
	margin: 1.5rem 0 0.5rem;
}

.menu__side {
	grid-area: search;
	align-self: center;
	justify-self: end;
	position: relative;
}

.menu__social {
	display: none;
	position: relative;
	grid-area: social;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
}

.menu__social-link {
	color: var(--color-link-social);
}

.menu__social-link:hover,
.menu__social-link:focus {
	color: var(--color-link-menu);
}

.menu__social-author {
	margin-top: 2rem;
	font-family: kudryashev-d-excontrast-sans, sans-serif;
	font-weight: 300;
	font-size: 2.5vw;
	color: var(--color-link-menu);
	will-change: transform;
}

.menu__social-author:hover,
.menu__social-author:focus {
	color: var(--color-link-social);
}

.search {
	white-space: nowrap;
}

.search svg {
	width: 13px;
	margin-left: 1rem;
}

.line-link {
	color: var(--color-link-menu);
	white-space: nowrap;
	overflow: hidden;
	position: relative;
	display: inline-block;
	cursor: pointer;
	/* font-size: 17px; */
	font-size: 0.75rem;
    font-weight: 400;
    text-transform: uppercase;
}

.line-link:hover,
.line-link:focus {
	color: var(--color-link-menu)
}

.line-link::before {
	content: '';
	height: 1px;
	width: 100%;
	background: currentColor;
	position: absolute;
	top: 92%;
	transition: transform 0.3s;
	transform-origin: 0% 50%;
	transform: scaleX(0);
}

.line-link:hover::before {
	transform: scaleX(1);
	transform-origin: 100% 50%;
}

@media screen and (min-width: 55.5em) {
	body {
		--menu-padding: 2rem;
	}

	/* .frame {
		display: flex;
	}
	.frame__links {
		margin: 0 0 0 2rem;
	} */
	.menu {
		/* top: 2rem;
		left: 3rem;
		right: 3rem;
		bottom: 2rem; */
		top: 0.5rem;
		left: 1.5rem;
		right: 1.5rem;
		bottom: 1rem;
	}

	.menu__top {
		grid-template-columns: auto minmax(200px, 417px) auto;
		grid-template-areas: '... nav ...';
		/* grid-template-columns: 5rem 10% minmax(200px,417px) 10% 5rem;
		grid-template-areas: 'title ... nav ... search'; */
	}

	.menu__content {
		grid-template-rows: 1fr 10vh;
		grid-template-columns: 5rem 10% minmax(200px, 417px) 10% 5rem;
		grid-template-areas:
			'... ... links back ...'
			'tagline tagline social social social';
	}

	.menu__tagline {
		font-size: 17.5vw;
		font-size: clamp(1.5rem, 17.5vh, 8rem);
	}

	.menu__back {
		height: 200px;
		margin-top: calc(5.5vh + 1.5rem);
	}

	.menu__social {
		display: flex;
	}
}