/* Theme */
@import url("https://use.typekit.net/fyt6bvn.css");
@import url("./base.css");

/* Navbar */
@import url("./navbar.css");
@import url("https://use.typekit.net/lon4huw.css");

/* Hover Effect */
@import url("./HoverEffect.css");

.container {
    text-align: center;
    padding: 20px;
    margin: 0 auto;
}

.p0_25 {
    padding: 0.25rem;
}

.loading {
    filter: blur(10px);
    clip-path: inset(0);
}

.loaded {
    filter: blur(0px);
    transition: filter 0.5s linear;
}